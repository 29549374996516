.products {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.productContainer {
  /* border: 1px solid #414141; */
  margin-bottom: 110px;
}

.TopUl {
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.productsCard {
  display: flex;
  width: 50vw;
  height: 300px;
  position: relative;
  border: 2px solid #a2a2a2;
  margin-top: 30px;
  box-shadow: 0 5px 20px 2px #0000001a;
}

.productsImage {
  flex: 1;
  height: inherit;
  position: relative;
  display: flex;
  justify-content: center;
}
.productsImage img {
  width: inherit;
  height: 99%;
  object-fit: contain;
  object-position: center;
  /* border: 2px solid red; */
}
.productsContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 10px;
}
.productsContent .makeNmodel {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #ff8c00;
}

.productsContent .tagName {
  background-color: #414141;
  color: #fff;
  padding: 10px;
}

.productsContent .inquiry {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 50px;
}
.productContainer .inquiry .btn {
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  width: 120px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}
.productContainer .inquiry .btn1 {
  background-color: #f47d06;
}
.productContainer .inquiry .btn2 {
  background-color: #1acd11;
}

.productContainer .inquiry .btn:hover {
  color: #414141;
}

@media screen and (max-width: 768px) {
  .productsCard {
    width: 90vw;
  }
}
@media screen and (max-width: 425px) {
  .productsContent {
    margin-right: 0px;
  }
  .productsCard {
    height: 600px;
    flex-direction: column;
  }
  .productsCard .name {
    margin-left: 10px;
  }
  .productsImage {
    height: 50%;
  }
  .productsImage img {
    height: 100%;
  }
}
