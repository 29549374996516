.aboutpp p {
  line-height: 7rem;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .tagLineAbout {
    top: 150px;
  }
}
@media screen and (max-width: 425px) {
  .aboutpp p {
    font-size: 4.5rem;
    line-height: 5rem;
  }
  .abouttaglinkk p {
    margin-top: 150px;
  }
}
