.tagLine {
  width: 80vw;
  position: absolute;
  top: 150px;
}
.herosection {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.inst{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.herosection p {
  flex: 2;
  font-size: 4rem;
  line-height: 3rem;
  letter-spacing: 2px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  color: #ffffff;
  line-height: 4rem;
}

.herosection .imageDiv {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageDiv .imgBox {
  width: 250px;
  height: 250px;
  position: relative;
}
.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media screen and (max-width: 768px) {
  .herosection p {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 425px) {
  .tagLine {
    top: 50px;
  }
  .herosection {
    flex-direction: column-reverse;
  }
  .herosection p {
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
