.notFound {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.notTag {
  top: 150px;
  font-weight: 700;
}
.notTag span{
  font-size: 18px;
  color: #fff;
}
.notTag1 {
  top: 500px;
}

@media screen and (max-width: 425px) {
  .notTag {
    top: 200px;
    font-size: 4rem;
  }
  .notTag span{
    font-size: 12px;
  }
  .notTag1 {
    top: 600px;
  }
}
