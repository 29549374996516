.services {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.servicescontainer {
  margin-bottom: 110px;
}

.industry {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #012a42;
}

.ourclient{
  margin-bottom: 80px;
}

@media screen and (max-width: 768px) {
  .servicesHeadingSection p {
    top: 500px;
    font-size: 2rem;
  }
}
