.flexCard {
  width: 90vw;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.card {
  width: 190px;
  height: 120px;
  transition: all 0.5s;
  box-shadow: 15px 15px 30px rgba(25, 25, 25, 0.11),
    -15px -15px 30px rgba(60, 60, 60, 0.082);
  text-align: center;
  overflow: hidden;
}

.card:hover {
  height: 254px;
  background: linear-gradient(360deg, #edededc5 60%, hsla(0, 0%, 13%, 1) 70%);
}

.card .header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #212121;
  margin-bottom: 16px;
}

.card .header .img-box {
  width: 50px;
}

.card .header .title {
  font-size: 0.6em;
  letter-spacing: 0.1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 4px 0 14px 0;
  transition: all 0.5s;
  color: #edededc5;
}

.card:hover .header {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 96%);
}

.card:hover .card .header .title {
  padding: 0;
}

.card .content {
  display: block;
  text-align: justify;
  color: #212121;
  margin: 0 18px;
  font-size: 10px;
}

.card .content p {
  transition: all 0.5s;
  font-size: 1em;
  margin-bottom: 8px;
}

.card .content a {
  color: #1d8122;
  cursor: pointer;
  transition: all 0.5s;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
}

.card .content .btn-link:hover {
  border-bottom: 1px solid #1d8122;
}
