.about {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.aboutcontainer {
  margin-bottom: 100px;
}

.aboutHeadingSection {
  position: absolute;
  top: 500px;
  font-size: 3rem;
  font-weight: 600;
  padding: 90px;
  color: #012a42;
}

@media screen and (max-width: 768px) {
  .aboutHeadingSection {
    padding: 80px;
    text-align: center;
  }
}
@media screen and (max-width: 425px) {
  .aboutHeadingSection {
    top: 650px;
    font-size: 2.2rem;
    padding: 20px;
  }
}
