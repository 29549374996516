.footer {
  width: 100vw;
  height: 50px;
  background: rgb(252, 199, 0);
  background: linear-gradient(
    90deg,
    rgba(252, 199, 0, 1) 0%,
    rgba(243, 106, 62, 1) 78%
  );
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

/* ============ Left Div =========== */
.left {
  flex: 1;
  height: 50px;
  display: flex;
  justify-content: center;
}
.left .imageFooter {
  width: 60px;
  height: 60px;
  position: relative;
}
.imageFooter img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.left p{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-left: 5px;
}

/* ============ Right Div =========== */
.right {
  flex: 1;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.link {
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.link:hover {
  color: #414141;
}
