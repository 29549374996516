.textme {
  background-color: #fff;
  border: 1px solid #25d366;
  padding: 5px;
  position: relative;
  width: 6.2em;
  height: 2em;
  transition: 0.5s;
  font-size: 17px;
  border-radius: 0.4em;
}

.textme p {
  position: absolute;
  top: 0.2em;
  left: 1.15em;
  margin: 0;
  padding: 0;
  transition: 0.5s;
  color: #25d366;
}

.textme svg {
  position: absolute;
  top: 0.45em;
  right: 0.5em;
  margin: 0;
  padding: 0;
  opacity: 0;
  transition: 0.5s;
  height: 1em;
  fill: #fff;
}

.textme:hover p {
  left: 0.5em;
  color: #fff;
}

.textme:hover svg {
  opacity: 1;
}

.textme:hover {
  background-color: #25d366;
  cursor: pointer;
}
