.ProductItems {
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  background-color: #ff4d00;
  padding: 5px;
  border-radius: 2px;
  z-index: 200;
}
