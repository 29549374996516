.home {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.container {
  width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.container > .innerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  /* border: 1px solid yellowgreen; */
}
.span {
  width: 300px;
  height: 3px;
  margin-bottom: 20px;
}
.span1 {
  background-color: #012a42;
}
.span2 {
  background-color: #fff;
}
.span3 {
  background-color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
}
/* ============ Services ============ */

.serviceSection .leftService {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.leftService .serviceDiv1 .title {
  font-weight: 400;
  font-size: 18px;
  color: #f36a3e;
  letter-spacing: 3px;
}
.leftService .serviceDiv1 h1 {
  color: #012a42;
  text-transform: capitalize;
  margin-top: 10px;
}
.leftService .serviceDiv2 p {
  font-weight: 400;
  font-size: 0.9rem;
  color: #aebdc7;
  line-height: 1.4rem;
  letter-spacing: 1px;
  text-align: justify;
}
.leftService .serviceDiv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.serviceSection .rightService {
  flex: 1.2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.serviceDiv4 {
  width: 300px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: -20px;
  margin: 30px;
  /* border: 1px solid #f36a3e; */
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 1px 1px 1px 1px #f36a3e;
}
.serviceDiv4 .svg1 {
  width: 210px;
  height: 210px;
  object-fit: cover;
  object-position: center;
}
.serviceDiv4 .icon {
  position: absolute;
  top: 45px;
  left: 115px;
  color: #ffffff;
}

.serviceDiv4 .detailContent {
  display: flex;
  flex-direction: column;
  margin-top: -30px;
  gap: 10px;
}
.serviceDiv4 .detailContent h3 {
  display: flex;
  justify-content: center;
  color: #012a42;
}
.serviceDiv4 .detailContent p {
  color: #aebdc7;
  line-height: 1.5rem;
  word-spacing: 1px;
  text-align: justify;
  font-size: 14px;
}

/* ============ About ============ */
.aboutSection .leftAbout {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c7c4c153;
  border-radius: 10px;
}
.leftAbout .imageDivAbout {
  width: 400px;
  height: 400px;
  position: relative;
}
.leftAbout .imageDivAbout img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  object-position: center;
}
.aboutSection .rightAbout {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.rightAbout .aboutDiv1 .title {
  font-weight: 400;
  font-size: 18px;
  color: #f36a3e;
  margin: 25px;
  letter-spacing: 3px;
}
.rightAbout .aboutDiv1 h1 {
  color: #012a42;
  text-transform: capitalize;
  margin-top: 10px;
  margin: 25px;
}
.rightAbout .aboutDiv2 p {
  font-weight: 400;
  font-size: 0.9rem;
  color: #aebdc7;
  line-height: 1.4rem;
  letter-spacing: 1px;
  text-align: justify;
  margin: 25px;
}
.rightAbout .aboutDiv3 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

/* ============ Contact Detail Section =========== */
.contactDetailSection {
  position: absolute;
  bottom: 20px;
  z-index: 12;
}
/* ============ Logo Section =========== */
.logoSection {
  position: absolute;
  bottom: 450px;
  z-index: 12;
}
.logoSection .divLogoSection {
  width: 200px;
  height: 200px;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
}
.divLogoSection img {
  width: 98%;
  height: 98%;
  object-fit: cover;
  object-position: center;
}
/* ============ Testimonial (Team Members) Section =========== */
.testimonialSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonialSection .teammember {
  text-transform: capitalize;
  letter-spacing: 3px;
  color: #f36a3e;
}

.contactSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f36a3e;
}

/* ============ AnimationHome Section =========== */
.animationSection {
  position: absolute;
  top: 500px;
  right: 50px;
}

@media screen and (max-width: 768px) {
  /* ============ Services ============ */
  .serviceSection {
    flex-direction: column;
  }
  /* ============ About ============ */
  .aboutSection {
    flex-direction: column;
  }
  /* ============ AnimationHome Section =========== */
  .animationSection {
    top: 490px;
    right: 40px;
  }
}

@media screen and (max-width: 425px) {
  /* ============ Logo Section =========== */
  .logoSection {
    bottom: 560px;
  }
  /* ============ AnimationHome Section =========== */
  .animationSection {
    top: 540px;
    right: 10px;
  }
}
