.flexCardOUR{
    gap: 80px;
}
/*Magic card*/
.cardour {
  width: 190px;
  height: 150px;
  background: #252525;
  overflow: visible;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.card-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  padding: 0 1rem;
}

.card-img {
  --size: 100px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  transform: translateY(-50%);
  background: #42caff;
  background: linear-gradient(to bottom, #42caff 0%, #e81aff 100%);
  position: relative;
  transition: all 0.3s ease-in-out;
}

.card-img img {
  width: 98%;
  height: inherit;
  object-position: center;
  object-fit: contain;
  border-radius: 30px;
}

.card-img::before {
  content: "";
  border-radius: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  height: 90%;
  transform: translate(-50%, -50%);
  border: 1rem solid #e8e8e8;
}

/*Text*/
.text-title {
  text-transform: uppercase;
  font-size: 0.75em;
  color: #ffffff;
  letter-spacing: 0.05rem;
}

/*Hover*/
.cardour:hover .card-img {
  --size: 110px;
  width: var(--size);
  height: var(--size);
}
