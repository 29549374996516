/* Reseting */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Mobile786{
  font-size: 12px;
  font-weight: 700;
  color: #f36a3e;
}


@keyframes background-color {
  0% {
    background: linear-gradient(to bottom, #000428, #004683);
  }

  25% {
    background: linear-gradient(135deg, #1a4223, #5ee95e);
  }

  50% {
    background: linear-gradient(to bottom, #421a31, #e95ed2);
  }

  100% {
    background: linear-gradient(-135deg, #fae37d, #881da8, #20668f);
  }
}

.row {
  width: 90vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.container1 {
  margin-top: 80px;
}

.container1 .col-lg-4 {
  display: flex;
  justify-content: center;
}

.card11 {
  width: 300px;
  height: 300px;
  transform-style: preserve-3d;
  perspective: 500px;
  border: none;
  background-color: inherit;
}

.card11 .face {
  position: absolute;
  color: #1b1c2b;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.06);
  transform-style: preserve-3d;
  transition: 0.5s;
  backface-visibility: hidden;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.card11 .face.front-face,
.card11 .face.back-face {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card11 .face.front-face .profile {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.card11 .face.front-face .name {
  letter-spacing: 2px;
}

.card11 .face.front-face .designation {
  font-size: 0.8rem;
  color: #f36a3e;
  letter-spacing: 0.8px;
}

.card11:hover .face.front-face {
  transform: rotateY(180deg);
}

.card11 .face.back-face {
  position: absolute;
  background: rgba(255, 255, 255, 0.06);
  transform: rotateY(180deg);
  padding: 20px 30px;
  text-align: center;
  user-select: none;
}

.card11 .face.back-face .fa-quote-left {
  position: absolute;
  top: 25px;
  left: 25px;
  font-size: 1.2rem;
}

.card11 .face.back-face .fa-quote-right {
  position: absolute;
  bottom: 35px;
  right: 25px;
  font-size: 1.2rem;
}

.card11:hover .face.back-face {
  transform: rotateY(360deg);
}

@media (max-width: 991.5px) {
  .col-lg-4 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
}
