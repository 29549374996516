.contact {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.contactdiv {
  width: 70vw;
  background-image: url(../../Assets/CONTACTBACK.png);
  border-radius: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  flex-wrap: wrap;
  gap: 10px;
  z-index: 14;
}
.contactcontainer {
  margin-bottom: 110px;
}

.contactdiv1 {
  border: 1px solid #3bc5ab;
  flex: 1;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contactTitle1 {
  font-weight: 600;
  font-size: 14px;
  color: #343434;
  letter-spacing: 1px;
}
.contactdiv1 h1 {
  color: #fff;
  font-size: 3rem;
  letter-spacing: 2px;
}

.conP > p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
  .contactdiv {
    width: 90vw;
    gap: 15px;
    padding: 2rem;
  }
}
@media screen and (max-width: 425px) {
  .contactdiv {
    padding: 2.2rem;
  }
}
