.social-links,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-btn {
  cursor: pointer;
  height: 50px;
  width: 50px;
  font-family: "Titillium Web", sans-serif;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: white;
  margin: 5px;
  transition: 0.3s;
  justify-content: center;
}

.social-btn svg {
  height: 24px;
  width: 24px;
}

.social-btn span {
  width: 0px;
  overflow: hidden;
  transition: 0.3s;
  text-align: center;
  margin-left: 5px;
}

.social-btn:hover {
  width: 150px;
  border-radius: 5px;
}

.social-btn:hover span {
  padding: 2px;
  width: 110px;
}

#instagram .icon1 {
  fill: #f21d6f;
}

#linkedin .icon2{
  fill: #0e76a8;
}

#facebook .icon3{
  fill: #386c9a;
}
