.card777 {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card786 {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid #414141;
}

.card786 .title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.card786 .form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.card786 .group {
  position: relative;
}

.card786 .form .group label {
  font-size: 14px;
  color: rgb(99, 102, 102);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.card786 .form .group input,
.card786 .form .group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  outline: 0;
  width: 100%;
  background-color: transparent;
}

.card786 .form .group input:placeholder-shown + label,
.card786 .form .group textarea:placeholder-shown + label {
  top: 10px;
  background-color: transparent;
}

.card786 .form .group input:focus,
.card786 .form .group textarea:focus {
  border-color: #3366cc;
}

.card786 .form .group input:focus + label,
.card786 .form .group textarea:focus + label {
  top: -10px;
  left: 10px;
  background-color: #fff;
  color: #3366cc;
  font-weight: 600;
  font-size: 14px;
}

.card786 .form .group textarea {
  resize: none;
  height: 100px;
}

.card786 .form button {
  background-color: #ffbb00;
  color: #414141;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.card786 .form button:hover {
  background-color: #278b36;
  color: #fff;
}

.backBTN {
  height: 45px;
  margin-top: 20px;
  /* width: 120px; */
  background: #333;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Consolas, Courier New, monospace;
  border: solid #404c5d 1px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: 500ms;
  transition: 500ms;
  border-radius: 5px;
  background: linear-gradient(145deg, #2e2d2d, #212121);
  -webkit-box-shadow: -1px -5px 15px #41465b, 5px 5px 15px #41465b,
    inset 5px 5px 10px #212121, inset -5px -5px 10px #212121;
  box-shadow: -1px -5px 15px #41465b, 5px 5px 15px #41465b,
    inset 5px 5px 10px #212121, inset -5px -5px 10px #212121;
}

.backBTN:hover {
  -webkit-box-shadow: 1px 1px 13px #20232e, -1px -1px 13px #545b78;
  box-shadow: 1px 1px 13px #20232e, -1px -1px 13px #545b78;
  color: #d6d6d6;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.backBTN:active {
  -webkit-box-shadow: 1px 1px 13px #20232e, -1px -1px 33px #545b78;
  box-shadow: 1px 1px 13px #20232e, -1px -1px 33px #545b78;
  color: #d6d6d6;
  -webkit-transition: 100ms;
  transition: 100ms;
}
