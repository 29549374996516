#mailID {
  transition: all 0.5s ease-in;
  color: #1f1e1e;
}
#mailID:hover {
  cursor: pointer;
  color: #ffffff;
}
.contactDetail {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
}
.contactDetail .contactDiv1 {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contactDetail .contactDiv2 {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
}

.contactDetail h4 {
  color: #1f1e1e;
  text-transform: uppercase;
  font-size: small;
  letter-spacing: 3px;
}
.contactDetail p {
  color: #ffffff;
  font-size: 14px;
}

.contactDetail .contactDiv1 p {
  font-weight: 700;
  font-size: 50px;
}

.div1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .contactDetail .contactDiv2 {
    gap: 50px;
  }
  .contactDetail p {
    font-size: 14px;
  }
  .contactDetail .contactDiv1 p {
    font-size: 30px;
  }
}

@media screen and (max-width: 425px) {
  .contactDetail .contactDiv2 {
    gap: 50px;
    flex-direction: column;
  }
  .contactDetail p {
    font-size: 14px;
  }
}
