.tagLineServices {
  top: 230px;
}
.serviceshero p {
  font-size: 8rem;
}

.ourservices {
  display: none;
}

@media screen and (max-width: 425px) {
  .tagLineServices {
    top: 230px;
  }
  .serviceshero p {
    font-size: 4.3rem;
    line-height: 4.5rem;
  }
  .ourservices {
    display: inline-block;
  }
}
